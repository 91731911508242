<template>
  <swiper
    v-bind="swiperConfig"
    @swiper="$emit('swiper', $event)"
  >
    <slot />
  </swiper>
</template>

<script setup lang="ts">
import type {SwiperOptions} from 'swiper';
import {Autoplay, Pagination, Swiper as SwiperInstance} from 'swiper';
import {Swiper} from 'swiper/vue';

const props = withDefaults(defineProps<{
  autoplay?: boolean | number
  pagination?: boolean
}>(), {
  autoplay: true,
  pagination: true,
});

defineEmits<{
  (e: 'swiper', swiper: SwiperInstance): void
}>();

const swiperConfig = computed<SwiperOptions>(() => {
  const config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 16,
    modules: [Autoplay, Pagination],
  };

  if (props.autoplay) {
    config.modules?.push(Autoplay);
    config.autoplay = {
      delay: typeof props.autoplay === 'number' ? props.autoplay : 1800,
    };
  }

  if (props.pagination) {
    config.pagination = {
      clickable: true,
      enabled: true,
    };
  }

  return config;
});
</script>

<style scoped>
::v-deep(.swiper-slide) {
  height: auto;
}

::v-deep(.swiper-pagination) {
  display: flex;
  gap: theme("padding.3");
  margin-top: theme("height.4");
  justify-content: center;
}

::v-deep(.swiper-pagination-clickable .swiper-pagination-bullet) {
  cursor: pointer;
}

::v-deep(.swiper-pagination-bullet) {
  @apply transition-colors duration-150;

  border-radius: 100px;
  background: theme("colors.black.400");
  display: block;
  height: theme("width.4");
  width: theme("width.4");
}

::v-deep(.swiper-pagination-bullet-active) {
  background: theme("colors.primary.DEFAULT");
}
</style>

<style src="swiper/css" />
